/*
  Logic to determine the nearest printer model is based on this list. Model numbers
  in this list should be rounded values of the smallest model number for certain type
  of printer family. For example:
    Product Family   Model Numbers Range                      PRINTERS_LIST
    LEBI SF          HP Smart Tank 210 - HP Smart Tank 227    SmartTank - 210
    LEBI AIO         HP Smart Tank 520 - HP Smart Tank 549    SmartTank - 520
    LEBI WL          HP Smart Tank 580 - HP Smart Tank 5108   SmartTank - 580, 5100
*/
const PRINTERS_LIST = {
  SMARTTANK: [210, 520, 580, 660, 710, 750, 790, 5100, 6000, 7000, 7300, 7600],
  DESKJET: [
    2600, 2700, 2800, 2900, 4100, 4200, 4300, 4800, 4900, 5000, 5100, 5200, 5800
  ],
  DESKJETPLUS: [4100, 6000, 6400],
  LASERJETPROMFPM: [126],
  LASERJETMFPM: [130, 230],
  LASERJETM: [100, 130, 200],
  ENVY: [5000, 6000, 6400],
  ENVYPRO: [6400],
  ENVYPHOTO: [6200, 7100, 7800, 8000, 8100],
  ENVYINSPIRE: [7200, 7900],
  OFFICEJET: [5200],
  TANGO: []
}

/*
  If there are additional name tokens, it may be a special derivative (like ADF)
  that has a separate family/base. For example: Printer-Series HP LaserJet MFP M234d
    Internal Name               Product ID
    Skyreach base               HP LaserJet MFP M234d
    Skyreach Wireless Flatbed   HP LaserJet MFP M234dw
    Skyreach Ethernet ADF       HP LaserJet MFP M234sdn
    Skyreach Wireless ADF       HP LaserJet MFP M234sdw
*/
const specificDerivatives = {
  LASERJETPROMFPM: {
    126: {
      values: ['nw', 'snw'],
      nw: 'LASERJETPROMFPM126WL',
      snw: 'LASERJETPROMFPM126ADF'
    }
  },
  LASERJETMFPM: {
    230: {
      values: ['sdw', 'sdwe', 'sdn', 'sdne'],
      model: 'LASERJETMFPM230ADF'
    }
  }
}

const splitNameIntoTokens = function (printerName) {
  return printerName.match(/[a-zA-Z]+|\d+/g) // [word,number]
}

const getPrinterModel = function (printerName) {
  const nameTokens = splitNameIntoTokens(printerName)
  if (!nameTokens || !PRINTERS_LIST[nameTokens[0]]) {
    return 'SMARTTANK210' // if a model could not be found default to SMARTTANK210
  }

  let prev = PRINTERS_LIST[nameTokens[0]][0] // default to the lowest model number

  // Stop if there is no modelNumber
  if (!nameTokens[1]) {
    return nameTokens[0] + prev // build with the default that was just found
  }

  for (let i = 1; i < PRINTERS_LIST[nameTokens[0]].length; i++) {
    // otherwise find a valid model number
    if (PRINTERS_LIST[nameTokens[0]][i] <= nameTokens[1]) {
      prev = PRINTERS_LIST[nameTokens[0]][i]
    } else {
      break // need to break instead of a returning, or we won't return on the highest model numbers
    }
  }
  // If the currently parsed model has a derivative reference check the last token to see if there is a valid derivative
  const derivatives = specificDerivatives[nameTokens[0]]?.[prev]

  if (derivatives) {
    const extraToken = nameTokens[2]?.toLowerCase() || ''
    // The last token in the model is present in the derivative list. HP LaserJet MFP M234sdn -> 'sdn'
    if (derivatives.values.includes(extraToken)) {
      return derivatives[extraToken] || derivatives.model
    }
  }

  return nameTokens[0] + prev
}

export const getParsedProductName = function (modelName) {
  let parsedName = ''
  if (modelName) {
    const spaceRegex = /^(.*)(\s)(?:[a-zA-Z]+)$/g
    const group = spaceRegex.exec(modelName) // filter special model modifiers (ie. Tango X)
    if (group) {
      parsedName = group[0]
      parsedName = parsedName
        .replace(/(\b(HP|Ink|plus|Advantage|Ultra|series)\b|\s)/gi, '')
        .toUpperCase()
        .trim()
    } else {
      parsedName = modelName
        .replace(/(\b(HP|Ink|plus|Advantage|Ultra|series)\b|\s)/gi, '')
        .toUpperCase() // replace 'HP' and whitespaces if we have a non-null model name.
    }
  }
  if (parsedName.indexOf('-') > -1) {
    parsedName = parsedName.split('-')[0]
  }
  return getPrinterModel(parsedName) // otherwise find a printer with a similar image.
}

export const getProductFamily = function (nearestModelName) {
  const productFamilyList = {
    LebiSF: ['SMARTTANK210'],
    LebiAIO: ['SMARTTANK520'],
    LebiWL: ['SMARTTANK5100', 'SMARTTANK580'],
    Tassel: ['DESKJET2800'],
    TasselUIA: ['DESKJET4900'],
    TasselPlus: ['DESKJET4200'],
    Trillium: ['DESKJET2900'],
    TrilliumPlus: ['DESKJET4300', 'DESKJET5800'],
    TrilliumUIA: ['DESKJET5100'],
    SkyReach: ['LASERJETMFPM230'],
    SkyReachADF: ['LASERJETMFPM230ADF'],
    Eyrie: ['LASERJETM200'],
    SayanWL: ['SMARTTANK710', 'SMARTTANK7000'],
    SayanLite: ['SMARTTANK660', 'SMARTTANK6000'],
    SayanADF: ['SMARTTANK750', 'SMARTTANK7300'],
    SayanPremiumPlus: ['SMARTTANK790', 'SMARTTANK7600'],
    Kay: ['LASERJETM100'],
    Gaheris: ['LASERJETM130', 'LASERJETMFPM130'],
    NovelliBase: ['ENVYINSPIRE7200'],
    NovelliPlus: ['ENVYINSPIRE7900'],
    NaritaBase: ['ENVYPHOTO8000'],
    NaritaPlus: ['ENVYPHOTO8100'],
    DragonflyBase: ['LASERJETPROMFPM126'],
    DragonflyWL: ['LASERJETPROMFPM126WL'],
    DragonflyADF: ['LASERJETPROMFPM126ADF'],
    Verona: ['ENVY5000', 'DESKJET5000'],
    VeronaPlus: ['OFFICEJET5200', 'DESKJET5200'],
    PalermoLow: ['ENVYPHOTO6200', 'ENVYPHOTO7100'],
    PalermoHigh: ['ENVYPHOTO7800'],
    Taiji: ['DESKJET2600'],
    Taccola: ['DESKJET2700', 'DESKJET4800'],
    TaccolaPlus: ['DESKJET4100', 'DESKJETPLUS4100'],
    Tango: ['TANGO'],
    Vasari: ['ENVY6000', 'DESKJETPLUS6000'],
    VasariPlus: ['ENVY6400', 'ENVYPRO6400', 'DESKJETPLUS6400']
  }
  for (const productFamily in productFamilyList) {
    if (productFamilyList[productFamily].indexOf(nearestModelName) !== -1) {
      return productFamily
    }
  }
  return 'LebiSF'
}
